import { useEffect, useState } from "react";
import { useTracking } from "../../context/tracking.context";
import { useLocation } from "react-router-dom";

export default function useGoogleAds(id, conversionLabel, enableTracking, cookiesAllowed) {
  const [gTagInitialized, setGTagInitialized] = useState(false);
  const trackingData = useTracking()[0];
  const location = useLocation();

  // initialize Google Ads with gtag
  useEffect(() => {
    if (id && enableTracking && !gTagInitialized) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

      document.head.appendChild(script);

      const dataLayerPush = document.createElement("script");
      dataLayerPush.type = "text/javascript";
      const code = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${id}');
      `;

      try {
        dataLayerPush.appendChild(document.createTextNode(code));
        document.body.appendChild(dataLayerPush);
      } catch (e) {
        dataLayerPush.text = code;
        document.body.appendChild(dataLayerPush);
      }

      setGTagInitialized(true);
    }
  }, [id, enableTracking, cookiesAllowed, gTagInitialized]);

  // track conversion
  useEffect(() => {
    if (cookiesAllowed === false || enableTracking === false || !trackingData) {
      return;
    }

    if (location.pathname !== '/order/complete') {
      return;
    }

    const { orderData, template, product } = trackingData;
    if (!orderData || !template || !product) {
      return;
    }

    // don't track orders with failed payment
    if (!orderData.finished) {
      return;
    }

    // these unused variables may be useful later
    const { order_id, grand_total, tax_total, shipment_total } = orderData.swellOrderDetails;

    typeof window.gtag === 'function' && window.gtag('event', 'conversion', {
      'send_to': `${id}/${conversionLabel}`,
      'value': grand_total,
      'currency': 'EUR',
      'transaction_id': order_id
    });
  }, [cookiesAllowed, enableTracking, location.pathname, gTagInitialized, id, trackingData, conversionLabel]);
}
