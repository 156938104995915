import clsx from 'clsx';
import React from "react"

function Button({ children, className, backgroundColor, backgroundColorHover, backgroundColorFocus, border, borderColor, textColor, disabled, secondary, ...props }) {
    //bg-surface w-full border-solid border-2 border-accent-selected text-accent-selected font-semibold py-2 px-4 rounded uppercase
    return <button {...props} disabled={disabled} className={clsx(`h-full`, border && `border-solid border-${borderColor}`, `bg-${backgroundColor}`, (backgroundColorHover && !disabled) && `hover:bg-${backgroundColorHover}`, (backgroundColorFocus && !disabled) && `focus:bg-${backgroundColorFocus}`, !disabled && `hover:shadow-lg transition duration-300 ease-in-out`, `text-${textColor}`, `font-semibold`, `py-2`, `px-4`, `rounded`, `shadow-md`, `uppercase`, disabled && 'opacity-50', secondary ? 'border' : 'border-2', className)}>
        {children}
    </button>;
}

Button.defaultProps = {
    backgroundColor: "white", //"bg-white"
    backgroundColorHover: "white", //"hover:bg-white"
    backgroundColorFocus: "white",
    borderColor: "black",
    textColor: "black",
    border: true,
    type: 'button',
    className: '',
    secondary: false
}

export default Button;