import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

export async function fetchTemplates(page = 1, sortBy = '', sortOrder = '', canvasId = -1) {
    //TODO: pagination
    const queryParams = new URLSearchParams();
    queryParams.append("populate", "*");
    queryParams.append("sort", `${sortBy || 'order'}:${sortOrder || 'ASC'}`);
    if (canvasId.toString().startsWith('g_')) {
        queryParams.append("filters[groups][id][$eq]", canvasId.toString().split('_')[1]);
    }
    else if (canvasId > 0) {
        queryParams.append("filters[canvas][id][$eq]", canvasId);
    }
    queryParams.append("pagination[page]", page);
    queryParams.append("pagination[pageSize]", 6);

    return await axios.get(`${API_URL}/api/templates?${queryParams.toString()}`);
}

export async function fetchFonts(page = 1) {
    //TODO: pagination
    return await axios.get(`${API_URL}/api/fonts?fields=name,slug&sort=name:ASC&pagination[pageSize]=100`);
}

export async function fetchFont(fontId) {
    if (!fontId) return undefined;
    return await axios.get(`${API_URL}/api/fonts/${fontId}`);
}

export async function fetchGroups(page = 1, featured = false) {
    return await axios.get(`${API_URL}/api/groups?populate=*&sort=name:ASC&pagination[pageSize]=100${featured ? '&filters[featured][$eq]=true' : ''}`);
}

export async function fetchGroup(groupId) {
    if (!groupId) return undefined;
    return await axios.get(`${API_URL}/api/groups/${groupId}?populate=*`);
}

export async function fetchCanvases(page = 1, featured = false) {
    return await axios.get(`${API_URL}/api/canvases?populate=*&sort=name:ASC&pagination[pageSize]=100&filters[widthInMillimeters][$ne]=0${featured ? '&filters[featured][$eq]=true' : ''}`);
}

export async function fetchCanvas(canvasId) {
    if (!canvasId) return undefined;
    return await axios.get(`${API_URL}/api/canvases/${canvasId}?populate=*`);
}

export async function fetchTemplate(templateId) {
    return await axios.get(`${API_URL}/api/templates/${templateId}?populate=*`);
}

export async function fetchTextContent(templateId) {
    return await axios.get(`${API_URL}/api/text-contents?populate=*&sort=key:ASC&pagination[pageSize]=100`);
}
export function getTextContent(base, key) {
    const res = base?.data?.data?.find((entry) => entry.attributes.key === key);
    return res ? { content: res.attributes.content, media: getMediaUrl(res.attributes.media), mediaType: (getMediaUrl(res.attributes.media) ? res.attributes.media.data.attributes.mime : '') } : { content: '', media: '', mediaType: '' }
}

export function getMediaUrl(mediaObj, format = 'large') {
    const full = mediaObj?.data?.attributes?.url?.startsWith('http') ? mediaObj.data.attributes.url : (mediaObj?.data?.attributes?.url ? `${API_URL}${mediaObj.data.attributes.url}` : '');
    if (!format) return full;
    const detail = mediaObj?.data?.attributes?.formats?.[format]?.url?.startsWith('http') ? mediaObj.data.attributes.formats[format].url : (mediaObj?.data?.attributes?.formats?.[format]?.url ? `${API_URL}${mediaObj.data.attributes.formats[format].url}` : '');
    if (!detail) return full;
    return detail;
}

export const ErrorLevels = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
}

export async function serverLog(level, message, additionalData = null) {
    return await axios.post(`${API_URL}/api/logs`, {
        data: {
            eventLevel: level,
            message,
            eventDate: new Date(),
            data: additionalData
        }
    });
}

export async function sendContactEmail(data) {
    return await axios.post(`${API_URL}/swell/contact`, data);
}

export async function uploadUserContent(data) {
    const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    }
    return await axios.post(`${API_URL}/swell/upload`, data, config);
}