import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { serverLog, ErrorLevels } from './helpers/api';
import swell from 'swell-js';
import util from 'util';

import './i18n';

document.body.style.zoom = 1.0;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
const ignoredLogs = ['validateDOMNesting', 'componentWillUnmount', 'warning-keys', 'ERR_BLOCKED_BY_CLIENT', 'ERR_NAME_NOT_RESOLVED'];
const wrapLog = (fun, level) => {
  return (...args) => {
    const ret = fun(...args);
    if (args.length) {
      const err = new Error();
      const msg = util.format(...args);
      for (const ignoredLog of ignoredLogs) {
        if (msg.includes(ignoredLog)) {
          return ret;
        }
      }
      if (window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1')) {
        return ret;
      }
      serverLog(level, msg, {
        orderData: JSON.parse(localStorage.getItem('orderData')),
        stackTrace: err.stack,
      }).catch(function () { });
    }
    return ret;
  }
}
const newConsole = {
  ...console,
  error: wrapLog(window.console.error, ErrorLevels.ERROR)
};
window.console = newConsole;